import { render, staticRenderFns } from "./Supplier.vue?vue&type=template&id=4cd2b610&scoped=true"
import script from "./Supplier.vue?vue&type=script&lang=ts"
export * from "./Supplier.vue?vue&type=script&lang=ts"
import style0 from "./Supplier.vue?vue&type=style&index=0&id=4cd2b610&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cd2b610",
  null
  
)

export default component.exports